const ReactAppStage = process.env.REACT_APP_API_STAGE;

//development

const dev = {
  API_ENDPOINT_URL:
    ReactAppStage == "Dev"
      ? "https://weddingtestapi.riolabz.com/api/v1"
      : ReactAppStage == "Uat"
        ? "https://eventoquatapi.riolabz.com/api/v1"
        : "https://api.eventoq.in/api/v1/",
};

const prod = {
  API_ENDPOINT_URL:
    ReactAppStage == "Dev"
      ? "https://weddingtestapi.riolabz.com/api/v1"
      : ReactAppStage == "Uat"
        ? "https://eventoquatapi.riolabz.com/api/v1"
        : "https://api.eventoq.in/api/v1/",
};

const test = {
  API_ENDPOINT_URL:
    ReactAppStage == "Dev"
      ? "https://weddingtestapi.riolabz.com/api/v1"
      : ReactAppStage == "Uat"
        ? "https://eventoquatapi.riolabz.com/api/v1"
        : "https://api.eventoq.in/api/v1/",
};

export const RAZORPAY_KEY =
  (process.env.REACT_APP_API_STAGE == "Dev" || process.env.REACT_APP_API_STAGE == "Uat")
    ? process.env.REACT_APP_RAZORPAY_TEST_KEY
    : process.env.REACT_APP_RAZORPAY_LIVE_KEY;


const getEnv = () => {
  console.log("env value", process.env.NODE_ENV);
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
