import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import Routes from 'routes';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import { Navigate,useHistory, useNavigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY, NO_PACKAGE_ENTRY } from 'configs/AppConfig';
import { AUTH_VENDOR_CATEGORIES } from 'constants/AuthConstant';

export const Views = () => {
  const { locale, direction } = useSelector((state) => state.theme);
  const currentAppLocale = resources[locale];
  const navigate = useNavigate();
  // const [vendorCategories, setVendorCategories] = useState([]);
  useBodyClass(`dir-${direction}`);

  useEffect(() => {
    if (window === undefined) {
      return;
    }
    const root = window.document.documentElement;
    root.setAttribute('dir', direction);
  }, [direction]);

  // useEffect(()=>{
  //   setVendorCategories(JSON.parse(localStorage.getItem(AUTH_VENDOR_CATEGORIES)))
  // },[])

  

  const {vendorCategories} = useSelector((state) => state.auth);
  console.log('vendor categories in app',vendorCategories)
  // const vendorCategories = JSON.parse(localStorage.getItem(AUTH_VENDOR_CATEGORIES));
  
  
  useEffect(() => {
    console.log('entered to main function', vendorCategories)
    if (vendorCategories?.length === 0) {
      // navigate(NO_PACKAGE_ENTRY);
      <Navigate to={NO_PACKAGE_ENTRY} />
    }else{
      <Navigate to ={AUTHENTICATED_ENTRY} />
      // navigate('/app/dashboards/listing-list')
      
    }
  }, [vendorCategories]); // Add dependencies

  // Register Firebase Messaging Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')  // Make sure this path is correct
    .then(function (registration) {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch(function (error) {
      console.error('Service Worker registration failed:', error);
    });
}

  return (
    <ConfigProvider direction={direction} locale={currentAppLocale.antd}>
      <Routes />
    </ConfigProvider>
  );
};

export default Views;
