import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

// Firebase config (replace with your own)
const firebaseConfig = {
  apiKey: "AIzaSyC8eCP0ynt7jlC9VNeEmUryZTZhF-wrotw",
  authDomain: "eventq-cf6f2.firebaseapp.com",
  projectId: "eventq-cf6f2",
  storageBucket: "eventq-cf6f2.firebasestorage.app",
  messagingSenderId: "6004740862",
  appId: "1:6004740862:web:3bd437ce9984c2c1640baf",
  measurementId: "G-70LKLFXS3Y"
};

const vapidKey = "BO-vnzf-sCmib9ov_9IhWqOczEGu469j1_iDH5pj3PUfnkp8DZPKk71DnxncGgIjpbg-qh4iJIynIRYb24-TRQU"


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get messaging instance
const messaging = getMessaging(app);



export { messaging, getToken };

// export const requestFCMToken = async () => {
//   return Notification.requestPermission()
//     .then((permission) => {
//       if (permission === "granted") {
//         return getToken(messaging, { vapidKey })
//       } else {
//         throw new Error("Noficiation not granted")
//       }
//     })
//     .catch((err) => {
//       console.error("Error getting FCM token: ", err)
//       throw err;
//     })
// }

// export const requestFCMToken = async () => {
//   return Notification.requestPermission()
//     .then((permission) => {
//       if (permission === "granted") {
//         return getToken(messaging, { vapidKey })
//       } else {
//         throw new Error("Noficiation not granted")
//       }
//     })
//     .catch((err) => {
//       console.error("Error getting FCM token: ", err)
//       throw err;
//     })
// }

export const requestFCMToken = async () => {
  try {
    // Attempt to get the FCM token
    const token = await getToken(messaging, { vapidKey });

    if (token) {
      console.log("FCM Token:", token);
      return token;
    } else {
      console.warn("No FCM token available.");
      return null; // Return null instead of throwing an error
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
    return null; // Ensure it returns null on failure instead of getting stuck
  }
};

